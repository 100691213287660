<template>
  <b-card class="cardEarning">
    <b-row class="pb-50">
      <!-- text and button -->
      <b-col
        lg="6"
        order-lg="1"
        order="2"
        class="d-flex justify-content-between flex-column"
      >
        <div class="mt-1 mt-lg-0">
          <h4 class="font-weight-bolder mb-25">
            Earning Reports
          </h4>
          <b-card-text class="font-weight-bold mb-2">
            Total Earnings Overview
          </b-card-text>
          <div class="pt-2 mb-lg-0">
            <h1 class="font-weight-bolder font-large-2 mb-25 text-primary">
              $ {{ statsData ? Math.round(statsData.totalEarnings) : 0 }}
            </h1>
            <b-card-text class="font-weight-bold mb-2">
              Total Earnings
            </b-card-text>
          </div>
        </div>
      </b-col>

      <!-- dropdown and chart -->
      <b-col
        lg="6"
        cols="12"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <div>Last 7 Days</div>
        <!-- apex chart -->
        <vue-apex-charts
          type="bar"
          height="150"
          :options="salesBar.chartOptions"
          :series="salesBar.series"
        />
      </b-col>
    </b-row>
    <hr>

    <!-- progress bar -->
    <b-row class="pt-50">
      <b-col
        cols="4"
        class="mb-2"
      >
        <div class="d-flex flex-column align-items-start justify-content-start">
          <div>
            <span class="played1-tag-class featherClass">
              <feather-icon
                icon="DollarSignIcon"
                class="text-{#7367f0}"
                size="14"
              />
            </span>
            <span class="font-weight-bolder font-small-2">7 Days </span>
          </div>
          <span
            class="mb-50 mt-75 font-weight-bolder font-small-5 text-dark pr-1"
          >
            $ {{ statsData ? statsData.last7DaysEarnings : 0 }}
          </span>
        </div>
        <b-progress
          value="50"
          max="100"
          height="6px"
          class="w-75"
          variant="text-{#7367f0}"
        />
      </b-col>
      <b-col
        cols="4"
        class="mb-2"
      >
        <div class="d-flex flex-column align-items-start justify-content-start">
          <span>
            <span class="completed-tag-class featherClass">
              <feather-icon
                icon="DollarSignIcon"
                class="text-info"
                size="14"
              />
            </span>
            <span class="font-weight-bolder font-small-2">Last Month</span>
          </span>
          <span
            class="mb-50 mt-75 font-weight-bolder font-small-5 text-dark pr-2"
          >
            $ {{ statsData ? statsData.last30DaysEarnings : 0 }}
          </span>
        </div>
        <b-progress
          value="50"
          max="100"
          height="6px"
          variant="info"
          class="w-75"
        />
      </b-col>
      <b-col
        cols="4"
        class="mb-2"
      >
        <div class="d-flex flex-column align-items-start justify-content-start">
          <span>
            <span class="rejected-tag-class featherClass">
              <feather-icon
                icon="DollarSignIcon"
                class="text-danger"
                size="14"
              />
            </span>
            <span class="font-weight-bolder font-small-2">Last Year</span>
          </span>
          <span
            class="mb-50 mt-75 font-weight-bolder font-small-5 text-dark pr-2"
          >
            $ {{ statsData ? statsData.lastYearEarnings : 0 }}
          </span>
        </div>
        <b-progress
          value="50"
          max="100"
          height="6px"
          variant="danger"
          class="w-75"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BProgress, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardText,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    statsData: {
      type: Object,
      default: () => null,
    },
    dateSequence: {
      type: Array,
      default: () => null,
    },
    earningSequence: {
      type: Array,
      default: () => null,
    },
    updatedColors: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      avgData: {
        sessions: 2700,
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        growth: '+5.2%',
        goal: 100000,
        users: 100000,
        retention: 90,
        duration: 1,
      },
      salesBar: {
        series: [
          {
            name: 'AUD',
            data: this.earningSequence,
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            sparkline: { enabled: true },
            toolbar: { show: false },
            height: 50,
          },
          grid: {
            show: false,
            padding: { left: 0, right: 0 },
          },
          states: {
            hover: {
              filter: { type: 'darken', value: 0.2 },
            },
          },
          colors: [
            '#7367f0',
            '#7367f0',
            '#7367f0',
            '#7367f0',
            '#7367f0',
            '#7367f0',
            '#7367f0',
          ],
          plotOptions: {
            bar: {
              columnWidth: '20%',
              distributed: true,
              endingShape: 'rounded',
            },
          },
          tooltip: {
            theme: 'light',
            x: {
              show: true,
              formatter(val) {
                return `${val}`
              },
            },
          },
          xaxis: {
            categories: this.dateSequence,
            labels: { show: false },
          },
          yaxis: { show: false },
          dataLabels: { enabled: false },
        },
      },
    }
  },
  methods: {
    kFormatter,
  },
}
</script>

    <style scoped>
.featherClass {
  padding: 3px 6px !important;
  margin-right: 10px !important;
}
.cardEarning {
  min-height: 380px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
