<template>
  <div>
    <div class="d-flex align-items-center d-sm-flex d-block mb-1 card-wrapper ml-2">
      <div
        class="d-flex w-100 breadcrumb-wrapper justify-content-start"
      >
        <BreadCrumb :bread-crumb-items="breadCrumbItems" />
      </div>

    </div>
    <b-row
      class="m-2"
    >
      <b-col
        lg="6"
        md="6"
      >
        <b-skeleton-img
          v-if="spinner"
        />
        <b-row v-else>
          <b-col
            lg="12"
          >
            <statistic-card-with-line-chart
              icon="DollarSignIcon"
              color="success"
              :statistic="statsData ? `$ ${statsData.upcomingSubscriptionAmount.amount} (${timeFormateConverter(statsData.upcomingSubscriptionAmount.nextPaymentDate)})` : 0"
              statistic-title="Upcoming Subscription Payment"
              :chart-data="activeUsers.series"
              style="pointer-events: none;"
            />
          </b-col>
          <b-col
            lg="6"
          >
            <b-skeleton-img
              v-if="spinner"
            />
            <statistic-card-with-line-chart
              v-else
              icon="DollarSignIcon"
              color="primary"
              :statistic="`$ ${statsData.businessRevenueSharingDetails.sharedRevenue || 0}`"
              statistic-title="Revenue Shared"
              :chart-data="activeUsers1.series"
              style="pointer-events: none;"
            />
          </b-col>
          <b-col
            lg="6"
          >
            <b-skeleton-img
              v-if="spinner"
            />
            <statistic-card-with-line-chart
              v-else
              icon="DollarSignIcon"
              color="primary"
              :statistic="`$ ${statsData.businessRevenueSharingDetails.pendingRevenue || 0}`"
              statistic-title="Revenue Pending"
              :chart-data="activeUsers2.series"
              style="pointer-events: none;"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-skeleton-img
          v-if="spinner"
        />
        <CardEarningReport
          v-else
          :stats-data="statsData ? statsData.earningDetails : null"
          :date-sequence="dateSequence"
          :earning-sequence="earningSequence"
        />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end mx-4">
      <span
        class="buttons"
      >
        <b-button
          variant="primary"
          :disabled="spinner"
          @click="changePaymentCard()"
        >
          Change Card
        </b-button>
      </span>
    </div>
    <ChangeCard
      ref="changePaymentCardModal"
    />
    <b-row
      class="m-2"
    >
      <b-col>
        <b-card>
          <b-tabs
            pills
            nav-class="nav-pill-primary d-flex justify-content-center"
          >
            <b-tab
              title="Subscriptions"
              active
            >
              <GetSubscriptionInvoiceList />
            </b-tab>
            <b-tab
              title="External Ads"
            >
              <GetExternalInvoiceList />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="m-2"
    >
      <b-col>
        <b-card>
          <b-tabs
            pills
            nav-class="nav-pill-primary d-flex justify-content-center"
          >
            <b-tab
              active
              title="Refunds"
            >
              <GetRefundList />
            </b-tab>
            <b-tab
              v-if="!isAgency"
              title="Venue Revenue"
            >
              <GetVenueRevenueShareList />
            </b-tab>
            <b-tab
              v-if="isAgency"
              title="Agency Revenue"
            >
              <GetAgencyRevenueList />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol, BRow, BSkeletonImg, BTab, BTabs,
} from 'bootstrap-vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import CardEarningReport from '@/components/account-setting/CardEarningReport.vue'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import ChangeCard from '@/views/groups/ChangeCard.vue'
import PackageFeatures from '@/common/enums/packagesFeatureTypeEnum'
import { showToast } from '@/common/global/functions'
import moment from 'moment'
import GetVenueRevenueShareList from '../groups/GetVenueRevenueShareList.vue'
import GetSubscriptionInvoiceList from '../groups/GetSubscriptionInvoiceList.vue'
import GetExternalInvoiceList from '../groups/GetExternalInvoiceList.vue'
import GetRefundList from '../groups/GetRefundList.vue'
import GetAgencyRevenueList from '../groups/GetAgencyRevenueList.vue'

export default {
  components: {
    GetSubscriptionInvoiceList,
    GetExternalInvoiceList,
    GetVenueRevenueShareList,
    GetRefundList,
    GetAgencyRevenueList,
    StatisticCardWithLineChart,
    CardEarningReport,
    BreadCrumb,
    BCard,
    BTab,
    BTabs,
    BRow,
    BCol,
    BButton,
    ChangeCard,
    BSkeletonImg,
  },
  data() {
    return {
      activeUsers: {
        series: [
          {
            name: 'Active Users',
            data: [750, 1000, 900, 1250, 1000, 1200, 1100],
          },
        ],
      },
      activeUsers1: {
        series: [
          {
            name: 'Active Users',
            data: [365, 390, 365, 400, 375, 400],
          },
        ],
      },
      activeUsers2: {
        series: [
          {
            name: 'Active Users',
            data: [400, 375, 400, 365, 390, 365],
          },
        ],
      },
      breadCrumbItems: [
        { name: 'Home', to: '/', active: false },
        { name: 'Businesses', to: '/groups-listing', active: false },
        {
          name: 'Applications',
          to: `/applications?groupId=${this.$route.query.groupId}`,
          active: false,
        },
        { name: 'Billing Details', to: null, active: true },
      ],
      statsData: null,
      spinner: false,
    }
  },
  computed: {
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(PackageFeatures.AGENCY)
    },
    dateSequence() {
      return this.statsData?.earningDetails ? this.statsData.earningDetails.last7DaysEarningsArray.map(item => item.date) : []
    },
    earningSequence() {
      return this.statsData?.earningDetails ? this.statsData.earningDetails.last7DaysEarningsArray.map(item => item.earning) : []
    },
  },
  mounted() {
    this.getStatsForUser()
  },
  methods: {
    changePaymentCard(invoiceId, amount) {
      this.$refs.changePaymentCardModal.show(invoiceId, amount)
    },
    async getStatsForUser() {
      try {
        this.spinner = true
        const response = await this.$axios.get('/pmp-ads/business-earning-details')
        this.statsData = response.data.data
      } catch (error) {
        console.error(error.message)
        showToast('Billing Details', error.message, 'danger')
      } finally {
        this.spinner = false
      }
    },
    timeFormateConverter(date) {
      if (date) {
        return moment.utc(date).format('DD-MM-YYYY')
      }
      return '---'
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.25) !important;
    border: 1px solid rgba(34, 41, 47, 0.125) !important;
  }
</style>
